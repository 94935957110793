//ObjectFit for IE9+
$(document).ready(function(){
    objectFitImages();
});

//Маска телефона
jQuery(function(jQuery){
    jQuery("input[name='homephone']").inputmask("+7 (9999) 99-99-99");
    jQuery("input[name='cellphone'], input[name='tel']").inputmask("+7 (999) 999-9999");
});

//Add-Remove menu depending on menu button existence(depending on screen resolution)
$(document).ready(function () {
    hideHoverMenu();
});
$(window).resize(function () {
    hideHoverMenu();
});
function hideHoverMenu() {
    if ($('.mobile').css('display') == 'table') {
        $('.menu').removeClass('hover-menu');
        if ($('.menu-arrow').length == 0) {
            $('<a class="dropdown-toggle menu-arrow"><span></span></a>').insertBefore('.dropdown-menu');
        }

    }
    else {
        $('.menu').addClass('hover-menu');
        $('nav').show();
        $('.menu-arrow').remove();
    }
}

//Smooth scroll
$(document).ready(function () {
    var $page = $('html, body');
    $('.scroll').click(function () {
        $page.animate({
            scrollTop: $('.news').offset().top - 30
        }, 800);
        return false;
    });
});

//Video block autoHeight
$(document).ready(function () {
    videoHeight();
});
$(window).resize(function () {
    videoHeight();
});
function videoHeight() {
    $('.video').css({
        "max-height": $(window).height() - $('header').outerHeight()
    })
}

//Share spoiler
$(document).ready(function () {
    $('.share-button').click(function () {
        $('.share .list').removeClass('opened');
        $(this).next().addClass('opened');
    })
});

//News loadMore
$(document).ready(function(){
    $(function () {
        $(".news .t-table-outer").slice(0, 2).show();
        $("#loadMore").on('click', function (e) {
            e.preventDefault();
            $(".news .t-table-outer:hidden").slice(0, 4).slideDown();
            if ($(".news .t-table-outer:hidden").length == 0) {
                $("#loadMore").fadeOut('slow');
            }
            $('html,body').animate({
                scrollTop: $(this).offset().top
            }, 1500);
        });
    });
});

//Open dropdown menu on click event (mobile)
$(document).ready(function () {
    $('.menu-arrow').click(function () {
        $('.dropdown-menu', $(this).parent()).slideToggle();
        $(this).parent().toggleClass('open');
    })
});

//Min-height of content btw header and footer
$(document).ready(function () {
    setTimeout(function(){$('.main-wrapper').css('min-height', $(window).height() - $('header').outerHeight() - $('footer').outerHeight())}, 1)
});

//Customized select
$(document).ready(function () {
    $(".chosen-select").chosen({disable_search_threshold: 10});
});

//Change name if the file was chosen
$(document).ready(function(){
    $('.upload').each(function () {
        $('.upload input').bind('change', function() {
            var files = $(this).prop("files");
            var names = $.map(files, function(val) { return val.name; }).join( ", " );
            $('.file-selected').html(names);
        })
    })
});

//Modal close
$(document).ready(function () {
    $('.modal-content > .icon-close').click(function () {
        $(this).parents().eq(2).modal('hide');
    })
});

//Lang spoiler
$(document).ready(function () {
    $('.language > div').click(function () {
        $('.languages-list', $(this)).slideToggle();
    })
});

//Modal close-button trigger
$(window).on('load', function () {
    $('.lb-close2').on('click', function () {
        $('.lb-close').trigger('click');
    })
});

$(document).ready(function () {
    $('body .chosen-container').each(function () {
        $(this).width($(this).width()+50);
    })
});